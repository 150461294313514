import React, { useState } from 'react';
// import './home.css';
import CustomCardCarousel from '../components/Card';
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import styles from "../pages/Step1.module.css";
import FontStyle1 from "../assets/EP.png";
import FontStyle2 from "../assets/EP (1).png";
import FontStyle3 from "../assets/EP (2).png";
import FontStyle4 from "../assets/EP (3).png";
import FontStyle5 from "../assets/EP (4).png";
import FontIcon1 from "../assets/Times New Roman.png";
import FontIcon2 from "../assets/, Georgia.png";
import FontIcon3 from "../assets/Helvetica , Arial.png";
import FontIcon4 from "../assets/Brush Script , Lucida Handwriting.png";
import FontIcon5 from "../assets/Cooper Black , Papyrus.png";
import FontIcon6 from "../assets/Courier, Consolas.png";

const cardData = [
    {
      image: FontStyle1,
      caption: "Serif",
      description: "Used by traditional industries like finance and law for reliability.",
      icons: [FontIcon1, FontIcon2],
      width: '6.625rem',
      height: '1rem',
    },
    {
      image: FontStyle2,
      caption: "Sans Serif",
      description: "Preferred by tech companies for a modern, clean look.",
      icons: [FontIcon3],
      width: '6.625rem',
      height: '1.3125rem',
    },
    {
      image: FontStyle3,
      caption: "Script",
      description: "Found in luxury brands and food products for elegance.",
      icons: [FontIcon4], 
      width: '13.625rem',
      height: '1.3125rem',
     
    },
    {
      image: FontStyle4,
      caption: "Display",
      description: "For brands targeting younger audiences or seeking uniqueness.",
      icons: [FontIcon5],
      width: '11.625rem',
      height: '1.3125rem',
    },
    {
      image: FontStyle5,
      caption: "Monospaced Fonts",
      description: "Associated with tech and coding for precision.",
      icons: [FontIcon6],
      width: '7.625rem',
      height: '1.3125rem',
    },
  
  ];


const BodySection = () => {
  return (
    <>

      <div className="container" 
      >
        <header className="mb-4">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-6" style={{padding:0}}>
                <h1 className="step-heading  text-md-left" style={{fontFamily:'Roboto', fontWeight:'400',fontSize: '1.0625rem'}}>Step 2/3</h1>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-8" style={{padding:0}}>
                <h2 className="logo-selection-heading text-md-left" style={{fontFamily:'Roboto', fontWeight:'700'}}>
                Select all of the Fonts styles you like
                </h2>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-6" style={{padding:0}}>
                <p className="preferences-text  text-md-left">
                  Your selections will help us understand your preferences.
                </p>
              </div>
            </div>
          </div>
        </header>
      </div>
      <CustomCardCarousel cardData={cardData}/>
   

    </>
  );
};

export default BodySection;
