import React from 'react';
import { Form, Container, Row, Col } from 'react-bootstrap';
import styles from "./Footer.module.css";
import linkedin from "../assets/linkedin.svg";
import insta from "../assets/instagram.svg";
import twitter from "../assets/twitter.svg";
import facebook from "../assets/facebook.svg";
import logo from "../assets/group-1000002329.svg";
import envelop from "../assets/Envelop.png";
const Footer = () => {
    return (
        <div className={styles.container}>
            <div className={styles.footerRow}>
                <div xs={12} sm={12} md={6} className={styles.firstColContainer}>
                <div className={styles.logoMakerParent}>
                <b className={styles.logoMaker}>
                    <p className={styles.logoText}>Logo</p>
                    <p className={styles.logoText}>Maker</p>
                </b>
                <img
                    className={styles.groupItem}
                    alt=""
                    src={logo}
                />
            </div>
                    <div className={styles.loremIpsumDolor}>
                        Lorem ipsum dolor amet consectetur adipiscing elit do eiusmod tempor
                        incididunt ut labore et dolore.
                    </div>
                    <div className={[styles.socialLinks, styles.item - 3].join(' ')}>
                <img className={styles.facebookIcon} alt="" src={linkedin} />
                        <img className={styles.facebookIcon} alt="" src={insta} />
                        <img className={styles.facebookIcon} alt="" src={twitter} />
                        <img className={styles.facebookIcon} alt="" src={facebook} />
                    </div>

                </div>
                <div>
                    <div className={styles.links}>
                        <div className={styles.company}>Company</div>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'flexStart', flexDirection: 'column',gap:"0.88rem" }}>
                            <div className={styles.singleService}>About company</div>
                            <div className={styles.singleService}>Company services</div>
                            <div className={styles.singleService}>Job opportunities</div>
                            <div className={styles.singleService}>Creative people</div>
                            <div className={styles.singleService}>Contact us</div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className={styles.links} style={{marginLeft:'1.95rem'}}>
                        <div className={styles.company}>Customer</div>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'flexStart', flexDirection: 'column' , gap:"0.88rem"}}>
                            <div className={styles.singleService}>Client Support</div>
                            <div className={styles.singleService}>Latest News</div>
                            <div className={styles.singleService}>Company Story</div>
                            <div className={styles.singleService}>Services</div>
                            <div className={styles.singleService}>Who we are</div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className={styles.links} style={{marginLeft:'2.76rem'}}>
                        <div className={styles.company}>Subscribe To Newsletter</div>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'flexStart', flexDirection: 'column', gap:'0.75rem' }}>
                            <div className={styles.emailText}>Enter your email address for receiving valuable newsletters.</div>
                            <div className={styles.enterYourEmail1}>
                                <input type='text' placeholder='Enter Your Email Address' style={{backgroundColor:'#141414', color:'white', width: '16.875rem',
height: '3rem', borderRadius: '0.3125rem', border: '1px solid #FFF'}} />
<img src={envelop} style={{position:'absolute',
    top:'0rem', left:'14.3rem', height:'3.24rem', width:'3rem'}}/>
                            </div>

                            <div className={styles.emailCopy} style={{marginTop:'1.25rem'}}>&copy; Copyright 2023-</div>
                            </div>
                        </div>
                </div>

            </div>
        </div>
    );
};

export default Footer;