import React from 'react';
import styles from "./Navbar.module.css";
import styles1 from "./Navbar1.module.css";
import { Link } from "react-router-dom";
import user from "../assets/User.png";
import logo from "../assets/group-1000002329.svg";

const Navbar = () => {
    return (
        <div className={styles.navbarParent}>
            <div className={styles.navbarFirstComp}>
                <div className={styles.logoMakerParent}>
                    <b className={styles.logoMaker}>
                        <p className={styles.logoText}>Logo</p>
                        <p className={styles.logoText}>Maker</p>
                    </b>
                    <img
                        className={styles.groupItem}
                        alt=""
                        // src="/group-1000002329.svg"
                        src={logo}
                    />
                </div>


                <ul className={styles.navbarSecondComp}>
                    <li className={styles.menuItems} >Home</li>
                    <li className={styles.menuItems}>Graphic Design Services 
                    {/* <svg xmlns="http://www.w3.org/2000/svg" width="12" height="8" viewBox="0 0 12 8" fill="none">
                        <path d="M1 1.54199L5.91666 6.45865L10.8333 1.54199" stroke="#535353" stroke-width="1.18" stroke-linecap="round" stroke-linejoin="round" />
                    </svg> */}
                        <ul>
                            <li className={styles.menuItems}>Logos</li>
                            <li className={styles.menuItems}>Brand ID</li>
                            <li className={styles.menuItems}>Comms Design</li>
                        </ul>
                    </li>
                    <li className={styles.menuItems}>Portfolio</li>
                    <li className={styles.menuItems}>About Us</li>
                    <li className={styles.menuItems}>FAQ</li>
                </ul>

            </div>

            {/* <div> <ul className={styles.navbarThirdComp}>
                <li className={styles.menuNumber}>919 244 2535</li>
                <li>
                    <img className={styles.image40Icon} alt="" src="/image-40@2x.png" />

                </li>
                <li style={{ margin: 0, padding: 0 }}>  <div className={styles.masterPrimaryButton2} >
                    <div className={styles.buttonText3}>Get Started</div>
                    <div className={styles.masterPrimaryButtonChild} />
                </div></li>
            </ul>
            </div> */}

            <div className={`d-flex justify-content-center align-items-center custom-nav-section${styles.navbarThirdComp}`} style={{paddingTop:'0.3rem'}}>
                <p className={`mx-2 mb-0 font-weight-normal${styles.menuNumber}`} style={{fontFamily:'Poppins', fontWeight:'500'}}>+9192442535</p>
                <span className="mx-2 position-relative ">
                    <img
                        src={user}
                        alt="user"
                        className="mr-2"s
                        style={{ borderRadius: "1.22px 0px 0px 0px" }}
                    />
                </span>
                <span className={`mx-2 line-divider ${styles1.line9}`}></span>
                <div className="d-flex justify-content-center align-items-center mx-2 get-started-container">
                    <button className={`btn ${styles1.getStartedBtn}`}>Get Started</button>
                </div>
            </div>

            {/* <div className={styles.logoMakerParent}>
                <b className={styles.logoMaker}>
                    <p className={styles.createAProfessionalLogoIn}>Logo</p>
                    <p className={styles.createAProfessionalLogoIn}>Maker</p>
                </b>
                <img
                    className={styles.groupItem}
                    alt=""
                    src="/group-1000002329.svg"
                />
            </div>
            <div className={styles.home}>
                <div className={styles.home1}>Home</div>
            </div>
            <div className={styles.menuItem}>
                <div className={styles.dropdownMenu}>
                    <div className={styles.graphicDesignServices}>
                        Graphic Design Services
                    </div>
                    <img className={styles.vectorIcon} alt="" src="/vector.svg" />
                </div>
                <div className={styles.dropdownMenuList}>
                    <div className={styles.graphicDesignServices}>Logos</div>
                    <div className={styles.graphicDesignServices}>Brand ID</div>
                    <div className={styles.graphicDesignServices}>Comms Design</div>
                </div>
            </div>
            <div className={styles.portfolio}>
                <div className={styles.portfolio1}>portfolio</div>
            </div>
            <div className={styles.aboutUs}>
                <div className={styles.home1}>About Us</div>
            </div>
            <div className={styles.faq}>
                <div className={styles.home1}>FAQ</div>
            </div>
            <div className={styles.div}>
                <div className={styles.div1}>919 244 2535</div>
            </div>
            <img className={styles.image40Icon} alt="" src="/image-40@2x.png" />

            <div className={styles.masterPrimaryButton2}>
                <div className={styles.buttonText3}>Get Started</div>
                <div className={styles.masterPrimaryButtonChild} />
            </div> */}

        </div>
    );
};

export default Navbar;