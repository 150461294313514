import styles from "./Homepage.module.css";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Animation from "../assets/Component 5.png";
import Animation2 from "../assets/Component4.png";
import { Carousel } from 'react-bootstrap';
import { Form, Container, Row, Col } from 'react-bootstrap';
import {useNavigate} from 'react-router-dom';
import './Form.css';
const Frame5 = () => {
  const navigate = useNavigate();
  return (
    <div className={styles.imagePlaceholderParent}>
      <Navbar />

      <Row className={styles.wrappingContainer}
      style={{
        display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', paddingBottom: '10rem',
        paddingTop: '4.52rem'
      }}>

        <Col sm={12} md={6} lg={6} xl={6}>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start', flexDirection: 'column', width: '29.1875rem', paddingLeft: '8.44rem' }}>
            <div className={styles.createAProfessionalContainer}>
              <p className={styles.createAProfessionalLogoIn}>
                <span>Make a free logo design in minutes with AI</span>
                <span className={styles.span}>.</span>
              </p>
            </div>
            <div className={styles.paragraph}>
              LogoMaker can make your big idea a beautiful reality.
            </div>


            <Form.Control as="select" className={styles.underlineInput}
              style={{
                fontFamily: 'Poppins',
                color: '#4C4C4C',
                lineHeight: '1.125rem',
                fontSize: '1rem',
                marginTop: '3.12rem',
                width: '26rem',
                paddingBottom: '1rem',
                fontWeight: 400,
                fontSize: '0.875rem'
              }}>
              <option><span style={{ display: 'flex', flexDirection: 'row' }}>Choose Industry <svg xmlns="http://www.w3.org/2000/svg" width="12" height="8" viewBox="0 0 12 8" fill="none">
                <path d="M1 1.54199L5.91666 6.45865L10.8333 1.54199" stroke="#535353" stroke-width="1.18" stroke-linecap="round" stroke-linejoin="round" />
              </svg></span></option>
              <option>Automotives and Transportation</option>
              <option>Business & Consulting</option>
              <option>Food, Beverage, Restaurant</option>
              <option>Travel & Holidays</option>
              <option>IT & Science</option>
              <option>Marketing</option>
            </Form.Control>

            <Form.Control
              type="text"
              placeholder="Company Name"
              className={styles.underlineInput}
              style={{
                fontSize: '1rem',
                paddingBottom: '1rem',
                color: '#4C4C4C',
                fontWeight: 400,
                marginTop: '0.88rem',
                width: '26rem',
                fontSize: '0.875rem'
              }}
            />


            <button className={[styles.masterPrimaryButton, styles.buttonText1].join(' ')}   onClick={() => navigate('/steps')}>
              <span className={styles.buttonText}>Make your logo</span>
            </button>
            <div className={styles.paragraph1}>No Credit Card Required</div>
          </div>
        </Col>
        <Col sm={12} md={6} lg={6} xl={6} style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-end', flexDirection: 'column', paddingLeft:'4rem' }}>


          <Carousel
         
            interval={1000}  fade
            indicators={false}
            prevIcon={null} 
            nextIcon={null}
            style={{width:'100%'}}
          >
            <Carousel.Item >
            
                <img className={styles.groupChild} alt="" src={Animation} />
       
            </Carousel.Item>


            <Carousel.Item >
     
                <img className={styles.groupChild} alt="" src={Animation2} />
           </Carousel.Item>

          </Carousel>


        </Col>
      </Row>


      <Footer />
    </div >
  );
};

export default Frame5;
